/*-----------------------
    Footer Style Here  
------------------------*/

.logo {
  a {
    img {
      max-height: 120px;
    }
  }
}

.footer-style-1 {
  background-color: var(--color-darkest);
  border-top: 1px solid var(--color-border);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(var(--color-black)),
    to(var(--color-blacker))
  );
  background-image: linear-gradient(
    0deg,
    var(--color-black),
    var(--color-blacker)
  );
  .footer-top {
    padding: 120px 0;
    .rainbow-footer-widget {
      .text-big {
        font-size: 21px;
        margin-top: 30px;
        padding-right: 13%;
        line-height: 37px;
        font-weight: 500;
      }
      .title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 10px;
      }
      .subtitle {
        font-weight: 400;
        color: var(--color-body);
        font-size: 16px;
        padding-right: 31%;
        line-height: 28px;
        margin-bottom: 32px;
      }
      .inner {
        margin-top: 13px;
      }
      .footer-link {
        list-style: none;
        padding: 0;
        li {
          margin-top: 5px;
          margin-bottom: 5px;
          a {
            font-size: 14px;
            display: block;
            color: var(--color-body);
            line-height: 25px;
          }
        }
      }
    }
  }
}

.newsletter-form {
  input {
    flex-grow: 1;
    width: auto;
    margin-right: 10px;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
}

.footer-style-default {
  border-top: 1px solid var(--color-border);
  background-color: var(--color-darkest);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(var(--color-black)),
    to(var(--color-blacker))
  );
  background-image: linear-gradient(
    0deg,
    var(--color-black),
    var(--color-blacker)
  );
  .footer-top {
    padding: 100px 0;
    padding-bottom: 70px;
    .rainbow-footer-widget {
      .text-big {
        font-size: 21px;
        margin-top: 30px;
        padding-right: 13%;
        line-height: 1.7;
        font-weight: 500;
        color: var(--color-body);
        margin-bottom: 30px;

        @media #{$md-layout} {
          font-size: 20px;
          padding-right: 0;
        }

        @media #{$sm-layout} {
          font-size: 20px;
          padding-right: 0;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 10px;
      }

      .subtitle {
        font-weight: 400;
        color: var(--color-body);
        font-size: 16px;
        padding-right: 31%;
        line-height: 28px;
        margin-bottom: 32px;

        @media #{$sm-layout} {
          padding-right: 0;
        }
      }

      .inner {
        margin-top: 13px;
      }

      .footer-link {
        list-style: none;
        padding: 0;

        li {
          margin-top: 5px;
          margin-bottom: 5px;

          a {
            font-size: 14px;
            display: inline-block;
            color: var(--color-body);
            line-height: 25px;
            transition: 0.3s;
            text-transform: capitalize;

            &:hover {
              color: var(--color-primary);

              &::after {
                width: 100%;
                left: 0;
                right: auto;
              }
            }
          }
        }
      }
    }
  }
  &.variation-two {
    border-top: 0;
  }
  &.no-border {
    border-top: 0;
  }
}

.footer-style-3 {
  background: none !important;
  border: none !important;
  margin-top: 96px;
  z-index: 5;
  .footer-top {
    background: var(--color-dark);
    padding-top: 50px;
  }
  .separator-animated {
    @media #{$small-mobile} {
      margin: 20px !important;
    }
  }
  .newsletter-form {
    input {
      @media #{$small-mobile} {
        margin-right: 0;
      }
    }
    button {
      padding: 0 20px;
    }
  }
}
