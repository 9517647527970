/*--------------------------
    Header Styles  
 --------------------------*/
.logo-dark {
  display: none;
}

.header-default {
  max-width: 100%;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-darker);
  color: #fff;
  font-size: 12px;
  z-index: 99;
  position: relative;
  .logo {
    a {
      display: flex;
      height: 80px;
      line-height: 80px;
      align-items: center;
      img {
        max-height: 80px;
      }
    }
  }

  .header-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &.header-relative {
    position: relative;
  }

  .header-right {
    display: flex;
    justify-content: flex-end;

    .header-btn {
      margin-left: 25px;
      @media #{$sm-layout} {
        display: none;
      }
    }
  }
  a,
  button {
    &.btn-default {
      @media #{$sm-layout} {
        height: 36px;
        line-height: 35px;
        padding: 0 10px;
        font-size: 12px;
      }
      &.btn-small {
        @media #{$sm-layout} {
          height: 36px;
          line-height: 35px;
          padding: 0 10px;
          font-size: 12px;
        }
      }
    }
  }

  &.header-transparent {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    background: transparent !important;
    backdrop-filter: inherit;
  }
}

.header-transparent-with-topbar {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  z-index: 99;
  @media #{$md-layout} {
    position: relative;
  }
  @media #{$sm-layout} {
    position: relative;
  }
  .header-top-bar {
    background-color: transparent;
    @media #{$md-layout} {
      background-color: var(--color-darker);
    }
    @media #{$sm-layout} {
      background-color: var(--color-darker);
    }
  }
  .header-default {
    background-color: transparent;
    background-image: none;
    @media #{$md-layout} {
      background-color: var(--color-darker);
    }
    @media #{$sm-layout} {
      background-color: var(--color-darker);
    }
  }
}

.hamberger-button {
  background: var(--color-primary-alt);
  border: 0 none;
  color: var(--color-white);
  width: 40px;
  height: 40px;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.header-left-align {
  .mainmenu-nav {
    margin-left: 30px;
  }
}

.rainbow-header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-dark) !important;
  width: 100%;
  height: 80px;
  animation: headerSticky 0.95s ease forwards;
  z-index: 9;
}

.rbt-fluid-header {
  .container-wrapper {
    max-width: 1600px;
  }
}

.mainmenu {
  &.one-menu-item {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      a {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0;
        padding-left: 17px;
      }
    }
  }
}

.header-round-btn {
  @media #{$sm-layout} {
    padding: 5px !important;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--color-white);
    border: none;
    font-size: var(--font-size-b1);
  }
}

@keyframes headerSticky {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
