.min-height-400 {
  min-height: 400px;
}

.blank-div {
  position: sticky;
  z-index: 100;
  background-color: #070710;
}

.gradient-background:before {
  content: "";
  width: 100px;
  height: 100px;
  background: var(--color-primary);
  position: absolute;
  left: 50%;
  top: -60px;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 100%;
  filter: blur(70px);
}

.footer-sns {
  width: 40px;
  height: 40px;
  border-radius: 35px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.footer-sns:first {
  margin-left: 0px !important;
}

.footer-sns:last {
  margin-right: 0px !important;
}

.warning-wrapper {
  background-color: #4d3800;
  border-radius: 0.4rem;
  padding: 2rem;
  border: 0px solid #e6a700;
  border-left: 5px solid #e6a700;
}

.tip-wrapper {
  background-color: #003100;
  border-radius: 0.4rem;
  padding: 2rem;
  border: 0px solid #009400;
  border-left: 5px solid #009400;
}
